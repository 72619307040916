.container {
  background: linear-gradient(
    101.21deg,
    rgba(24, 24, 24, 0.25) 0.52%,
    rgba(24, 24, 24, 0.25) 100%
  );
  font-size: 16px;
  padding: 14px 16px;
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: color 0.3s;

  &.--cursor {
    cursor: pointer;
  }

  &.--bordered {
    border: 1px solid rgba(51, 51, 51, 0.5);
  }

  &.--circle-prefix {
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background: #ffffff;
      opacity: 0.8;
      display: inline-block;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}
